import { Controller } from "@hotwired/stimulus"
import axios from "axios"
import Cookies from 'js-cookie'

import '@stripe/stripe-js';

import { loadStripe } from '@stripe/stripe-js';


export default class extends Controller {
  initialize() {
    this.loadEmbedStripeForm()
  }

  async loadEmbedStripeForm() {
    let key = this.data.get("key")
    let client = this.data.get("client")
    await loadStripe(key);

    let stripe = Stripe(key);
    const checkout = await stripe.initEmbeddedCheckout({
      clientSecret: client,
    });

    checkout.mount('#checkout');
    console.log(checkout)
  }
}
