import { application } from "./application"

import CheckoutController from "./checkout_controller"
application.register("checkout", CheckoutController)

import ProductController from "./product_controller"
application.register("product", ProductController)

import StripeController from "./stripe_controller"
application.register("stripe", StripeController)
