import { Controller } from "@hotwired/stimulus"
import axios from "axios"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["figure", "image"];

  setImage(event) {
    event.preventDefault()
    let imgage1 = "https://ispeakwithgeorgette-dev.s3.eu-west-3.amazonaws.com/37lioss0u6vpy02djggd0fj29tq3"
    let image = event.target.dataset.value



    console.log("event", event.target.dataset.value )
    console.log("figureTargets", this.figureTargets)
    console.log("imageTargets", this.imageTargets)



    this.imageTarget.src = image
    this.figureTarget.style.backgroundImage = "url(" + image + ")"


      // if (key === colorKey) {
      //   element.classList.add(value);
      // } else {
      //   element.classList.remove(value);
      // }


  }

  connect() {
  	// console.log("product image connect")
    // console.log(this.data.get("sadsadas "))
    // console.log(this.data.get("myValue"))
  }

  initialize() {
  	// console.log("product image initialize")
  }
}
