import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
import "./controllers"
import $ from "jquery";
import Rails from '@rails/ujs';
// import Rails from 'jquery.beautify-json';

import "@rails/actiontext"
import "trix"

Rails.start();

addEventListener("turbo:load", () => {
  const menu = $( "#mobile-menu" )
    $( "#mobile-menu-button" ).on( "click", function() {
      menu.toggle();
    } );
})
