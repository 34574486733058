import { Controller } from "@hotwired/stimulus"
import axios from "axios"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["delivery"];

  setDelivery({ target }) {
	 fetch("/checkout/delivery?delivery_id=" + target.value, {
			method: "POST",
			headers: {
				Accept: "text/vnd.turbo-stream.html",
				"X-CSRF-Token": Cookies.get('CSRF-TOKEN')
			}
    })
	  .then(r => r.text())
	  .then(html => Turbo.renderStreamMessage(html))
  }

  connect() {
  }

  initialize() {
  }
}
